<template>
  <div class="text-center white--text">
    <div class="banner d-flex">
      <img
        src="../../assets/images/oao_logo_white.png"
        alt="OAO"
      >
    </div>

    <div class="sign-in d-flex mt-0">
      <v-col class="col-12">
        <div class="sign-in-title">
          iAdOps
        </div>

        <v-col class="description">
          Numerous data sources. <br>
          One simple interface. <br>
        </v-col>

        <v-row class="justify-center">
          <v-btn
            :loading="isSigningIn"
            :disabled="isSigningIn"
            large
            @click="goHome()"
          >
            Return Home
          </v-btn>
        </v-row>

        <v-row class="caption pt-3 justify-center">
          {{ version }}
        </v-row>

        <auth-error />
      </v-col>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')

export default {
  name: 'SignOut',
  components: {
    AuthError: () => import('../components/AuthError'),
  },
  computed: {
    ...mapLayoutState({
      version: (state) => state.version,
    }),
    ...mapState({
      isShowingHelp: {
        get() {
          return this.$store.state.isShowingHelp
        },
        set(value) {
          this.$store.dispatch('isShowingHelp', value)
        },
      },
      user: (state) => state.user,
      isSigningOut: (state) => state.isSigningOut,
      isSigningIn: (state) => state.isSigningIn,
      didSignOutFail: (state) => state.didSignOutFail,
      didSignInFail: (state) => state.didSignInFail,
    }),
  },
  created() {
    this.signOut('Sign Out Page')
  },
  methods: {
    ...mapActions(['getHelp', 'hideHelp', 'signOut', 'signIn']),
    goHome() {
      this.$router.push('/')
    },
  },
}
</script>
