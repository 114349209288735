<template>
  <div class="sign-in">
    <div
      v-if="isLoading"
      class="loader"
    >
      <v-progress-circular
        :size="70"
        color="#055289"
        indeterminate
      />
    </div>

    <div
      v-else
      class="text-center white--text"
    >
      <v-row class="banner d-flex">
        <v-col>
          <v-img
            class="ma-auto"
            src="../../assets/images/oao_logo_white.png"
            alt="OAO"
            height="auto"
            max-width="95px"
          />
        </v-col>
      </v-row>

      <v-row class="sign-in-content d-flex">
        <v-col class="col-12">
          <div class="sign-in-title display-1">
            iAdOps
          </div>

          <div
            class="description"
          >
            Numerous data sources. <br>
            One simple interface. <br>
          </div>

          <v-row class="justify-center mt-3">
            <v-btn
              :loading="isSigningIn"
              :disabled="isSigningIn"
              large
              @click="signIn()"
            >
              Sign In
            </v-btn>
          </v-row>

          <v-row class="caption pt-3 justify-center">
            {{ version }}
          </v-row>
        </v-col>

        <auth-error />
      </v-row>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')

export default {
  name: 'SignIn',
  components: {
    AuthError: () => import('../components/AuthError'),
  },
  computed: {
    ...mapLayoutState({
      version: (state) => state.version,
    }),
    ...mapState({
      isShowingHelp: {
        get() {
          return this.$store.state.isShowingHelp
        },
        set(value) {
          this.$store.dispatch('isShowingHelp', value)
        },
      },
      user: (state) => state.user,
      isAutoAuthing: (state) => state.isAutoAuthing,
      isAuthCheckRequested: (state) => state.isAuthCheckRequested,
      isSigningIn: (state) => state.isSigningIn,
      didSignInFail: (state) => state.didSignInFail,
      isLoading() {
        const { isAutoAuthing } = this

        return isAutoAuthing
      },
    }),
  },
  methods: {
    ...mapActions(['getHelp', 'hideHelp', 'signIn']),
  },
}
</script>
