<template>
  <div class="auth">
    <Sign-In v-if="!user" />

    <Sign-Out v-else />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import SignOut from './SignOut'
import SignIn from './SignIn'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'Auth',
  components: { SignOut, SignIn },
  props: {
    actionName: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    currentComponent() {
      const { actionName } = this

      return actionName
    },
  },
}
</script>

<style lang="scss" scoped>
.auth {
  height: 100%;
}

@import "~src/styles/variables";

a {
  color: $color-monochrome-darkestblue;

  &:hover {
    color: $color-monochrome-white;
  }
}

.sign-in {
  align-items: center;
  background: linear-gradient(
          124.66deg,
          #00c6ff 0%,
          $color-monochrome-brightblue 100%
  );
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 10% 100px;

  @media screen and (min-width: $vuetify-sm-min-size) and (max-width: $vuetify-md-max-size) {
    padding: 10%;
  }

  @media screen and (max-width: $vuetify-xs-max-size) {
    padding: 10px;
  }
}

.banner {
  justify-content: center;
  text-align: center;

  img {
    max-width: 9ch;
  }
}

.sign-in-content {
  margin: 2rem;
  color: $color-monochrome-white;
  text-align: center;

  .sign-in-title {
    margin: 2rem;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1.71px;
    line-height: 49px;

    .label-super  {
      border-bottom: 3px solid white;
      display: inline-flex;
      font-size: small;
      line-height: 2ch;
      margin: 0;
      text-transform: uppercase;
      vertical-align: super;
    }
  }

  .description {
    margin: 2rem;
    font-size: $size-large;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 25px;
  }

  button {
    border-radius: 6px;
    background-color: white;
    color: $color-vibrant-blue;
    width: 250px;
    font-size: $size-large;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 25px;
  }

  .need-help {
    h2 {
      text-align: left;
    }
  }

  .need-help-activator {
    text-transform: uppercase;
  }

  .error-label {
    border-radius: 6px;
    letter-spacing: 0.05ch;
  }
}
</style>
